import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { IC_Categories, IC_Customer, IC_Expense, IC_Vehicle, IC_branches, IC_dashboard, IC_permissions, IC_pricing, IC_reservations, IC_settings, IC_Affiliation, } from '../../../assets/icons/navbar/index';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { PERMISSIONS } from '../../../utils/permissions';
import SvgColor from '../../svg-color';
// ----------------------------------------------------------------------
const icon = (name) => _jsx(SvgColor, { src: name, sx: { width: 1, height: 1 } });
const ICONS = {
    customers: icon(IC_Customer),
    fleet: icon(IC_Vehicle),
    dashboard: icon(IC_dashboard),
    branches: icon(IC_branches),
    classes: icon(IC_Categories),
    reservations: icon(IC_reservations),
    pricing: icon(IC_pricing),
    settings: icon(IC_settings),
    permissions: icon(IC_permissions),
    expenses: icon(IC_Expense),
    affiliation: icon(IC_Affiliation),
};
export const useNavConfig = () => useMemo(() => {
    const navConfigMemoized = [
        {
            subheader: 'Navigation',
            items: [
                {
                    title: 'dashboard',
                    path: PATH_DASHBOARD.dashboard.root,
                    icon: ICONS.dashboard,
                    permissionString: PERMISSIONS.dashboard.listPermission,
                },
                {
                    title: 'branches',
                    path: PATH_DASHBOARD.branches.root,
                    icon: ICONS.branches,
                    permissionString: PERMISSIONS.branch.listPermission,
                },
                {
                    title: 'fleet',
                    path: PATH_DASHBOARD.fleet.root,
                    icon: ICONS.fleet,
                    children: [
                        {
                            title: 'classes',
                            path: PATH_DASHBOARD.fleet.classes,
                            permissionString: PERMISSIONS.class.listPermission,
                        },
                        {
                            title: 'rentables',
                            path: PATH_DASHBOARD.fleet.rentables,
                            permissionString: PERMISSIONS.rentable.listPermission,
                        },
                        {
                            title: 'rentable types',
                            path: PATH_DASHBOARD.fleet.rentableTypes,
                            permissionString: PERMISSIONS.rentableType.listPermission,
                        },
                        {
                            title: 'rentable extra types',
                            path: PATH_DASHBOARD.fleet.rentableExtraTypes,
                            permissionString: PERMISSIONS.rentableType.listPermission,
                        },
                        {
                            title: 'brands',
                            path: PATH_DASHBOARD.fleet.brands,
                            permissionString: PERMISSIONS.brand.listPermission,
                        },
                        {
                            title: 'models',
                            path: PATH_DASHBOARD.fleet.models,
                            permissionString: PERMISSIONS.model.listPermission,
                        },
                    ],
                },
                {
                    title: 'pricing',
                    path: PATH_DASHBOARD.pricing.root,
                    icon: ICONS.pricing,
                    children: [
                        {
                            title: 'pricing groups',
                            path: PATH_DASHBOARD.pricing.pricingGroups,
                            permissionString: PERMISSIONS.pricingGroup.listPermission,
                        },
                        {
                            title: 'seasons',
                            path: PATH_DASHBOARD.pricing.seasons,
                            permissionString: PERMISSIONS.season.listPermission,
                        },
                    ],
                },
                {
                    title: 'reservation',
                    path: PATH_DASHBOARD.reservations.root,
                    icon: ICONS.reservations,
                    children: [
                        {
                            title: 'reservations',
                            path: PATH_DASHBOARD.reservations.list,
                            permissionString: PERMISSIONS.reservation.listPermission,
                        },
                        {
                            title: 'calendar',
                            path: PATH_DASHBOARD.reservations.calendar,
                            permissionString: PERMISSIONS.reservation.listPermission,
                        },
                    ],
                },
                {
                    title: 'affiliations',
                    path: PATH_DASHBOARD.affiliations.root,
                    icon: ICONS.affiliation,
                    roles: ['SUPERADMIN'],
                },
                {
                    title: 'customers',
                    path: PATH_DASHBOARD.customers.root,
                    icon: ICONS.customers,
                    permissionString: PERMISSIONS.customer.listPermission,
                },
                {
                    title: 'finance',
                    path: PATH_DASHBOARD.finance.root,
                    icon: ICONS.expenses,
                    children: [
                        {
                            title: 'invoices',
                            path: PATH_DASHBOARD.finance.invoice,
                            permissionString: PERMISSIONS.invoices.listPermission,
                        },
                        {
                            title: 'expenses',
                            path: PATH_DASHBOARD.finance.expense,
                            permissionString: PERMISSIONS.expenses.listPermission,
                        },
                        {
                            title: 'accounts',
                            path: PATH_DASHBOARD.finance.accounts.root,
                            children: [
                                {
                                    title: 'bank accounts',
                                    path: PATH_DASHBOARD.finance.accounts.bankAccounts,
                                    permissionString: PERMISSIONS.bankAccount.listPermission,
                                },
                                {
                                    title: 'branch cashboxes',
                                    path: PATH_DASHBOARD.finance.accounts.branchCashboxes,
                                    permissionString: PERMISSIONS.branchCashbox.listPermission,
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'settings',
                    path: PATH_DASHBOARD.settings.root,
                    icon: ICONS.settings,
                    children: [
                        {
                            title: 'agencies',
                            path: PATH_DASHBOARD.settings.agency,
                            permissionString: PERMISSIONS.agency.listPermission,
                        },
                        {
                            title: 'users',
                            path: PATH_DASHBOARD.settings.users,
                            permissionString: PERMISSIONS.user.listPermission,
                        },
                        {
                            title: 'expense configs',
                            path: PATH_DASHBOARD.settings.expenseConfigs,
                            permissionString: PERMISSIONS.expenseConfig.listPermission,
                        },
                    ],
                },
                {
                    title: 'permission groups',
                    path: PATH_DASHBOARD.permissionGroups.root,
                    icon: ICONS.permissions,
                    permissionString: PERMISSIONS.group.listPermission,
                },
            ],
        },
    ];
    return navConfigMemoized;
}, []); // Empty dependency array ensures that useMemo runs only once
